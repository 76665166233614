/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React from 'react';
import { Button, ChangeContext, Form, Input, Switch, Text } from 'tea-component';
import { useForm, useField } from 'react-final-form-hooks';
import { Subscribe } from '@src/models';
import validate, { getMessage } from '@src/utils/validate';
import FileUpload from '../FileUpload';
import style from './index.module.scss';

const vilidateForm = ({ ChainId, Addr, OrgId, Tls, OrgCA, UserCert, UserKey, TLSHostName }: Subscribe) => {
  const checktls = validate.isEmptyString(TLSHostName) ? '请输入TLSHostName' : validate.TLSHostName(TLSHostName);
  const res = {
    ChainId: ChainId ? validate.chainId(ChainId) : '请输入区块链Id',
    Addr: Addr ? validate.Addr(Addr) : '请输入节点和端口号',
    OrgId: OrgId ? validate.orgId(OrgId) : '请输入组织Id',
    OrgCA: Tls && validate.isEmptyString(OrgCA) ? '请上传组织CA证书' : undefined,
    UserCert: validate.isEmptyString(UserCert) ? '请上传用户证书' : undefined,
    UserKey: validate.isEmptyString(UserKey) ? '请上传用户私钥' : undefined,
    // eslint-disable-next-line no-nested-ternary
    TLSHostName: Tls ? checktls : undefined,
  };
  return res;
};
export default function SubscribeForm({
  onSubmit,
  onCancel,
  initialData,
  submitText,
  cancelText,
}: {
  onSubmit: (value: Subscribe, context?: ChangeContext<React.SyntheticEvent<Element, Event>>) => void;
  onCancel: () => void;
  initialData?: string;
  submitText?: string;
  cancelText?: string;
}) {
  const { form, handleSubmit, validating, submitting } = useForm({
    onSubmit: (value) => {
      const subscribe = value;
      if (!subscribe.Tls) {
        subscribe.TLSHostName = '';
        subscribe.OrgCA = '';
      }
      onSubmit(subscribe);
    },
    initialValuesEqual: () => true,
    initialValues: {
      ChainId: initialData,
      Addr: '',
      OrgId: '',
      Tls: true,
      OrgCA: '',
      UserCert: '',
      UserKey: '',
      TLSHostName: '',
    },
    validate: vilidateForm,
  });
  const chainId = useField('ChainId', form);
  const addr = useField('Addr', form);
  const orgId = useField('OrgId', form);
  const orgCA = useField('OrgCA', form);
  const userCert = useField('UserCert', form);
  const userKey = useField('UserKey', form);
  const tLSHostName = useField('TLSHostName', form);
  const tls = useField('Tls', form);
  return (
    <form onSubmit={handleSubmit} className={style.content}>
      <Form>
        <Form.Item required label="区块链ID" {...getMessage(chainId)}>
          {!initialData && <Input {...chainId.input} placeholder="请输入区块链Id" />}
          {initialData && <Text theme="label">{initialData}</Text>}
        </Form.Item>
        <Form.Item
          required
          label="节点Ip和端口"
          {...getMessage(addr)}
          tips="请输入要订阅的链所在的服务器外网IP地址，及对外开放的RPC端口。请注意不能填127.0.0.1，长安链默认的RPC端口为12301-12304。"
        >
          <Input {...addr.input} placeholder="请输入节点Ip和端口" />
        </Form.Item>
        <Form.Item required label="组织ID" {...getMessage(orgId)}>
          <Input {...orgId.input} placeholder="请输入组织Id" />
        </Form.Item>
        <Form.Item required label="是否开启TLS" {...getMessage(tls)}>
          <Switch {...tls.input}>{tls.input.value ? '开启' : '关闭'}</Switch>
        </Form.Item>
        {tls.input.value && (
          <>
            <Form.Item
              required
              label="TLSHostName"
              {...getMessage(tLSHostName)}
              tips="如无自定义修改过CA服务，则长安链默认的TLS_Host_Name为chainmaker.org。"
            >
              <Input {...tLSHostName.input} placeholder="请输入TLSHostName" />
            </Form.Item>
            <Form.Item required label="组织证书" {...getMessage(orgCA)}>
              <FileUpload value={orgCA.input.value} name="组织证书" accept=".crt" onChange={orgCA.input.onChange} />
            </Form.Item>
          </>
        )}
        <Form.Item required label="用户证书" {...getMessage(userCert)}>
          <FileUpload value={userCert.input.value} name="用户证书" accept=".crt" onChange={userCert.input.onChange} />
        </Form.Item>
        <Form.Item required label="用户私钥" {...getMessage(userKey)}>
          <FileUpload value={userKey.input.value} name="用户私钥" accept=".key" onChange={userKey.input.onChange} />
        </Form.Item>
      </Form>
      <div className={style.rule}>
        <div className={style.rule_i}>
          1.请确保本处上传的组织证书、用户证书、用户私钥、节点信息都属于要订阅链，且节点信息和用户信息都归属于所填写的组织名下。
        </div>
        <div className={style.rule_i}>2.推荐填写所要订阅的链的共识节点的IP和端口，并确保网络通畅。 </div>
        <div className={style.rule_i}>3.如果您的节点同时参与了多条链，请根据chainID区分你要订阅具体哪条链。</div>
      </div>
      {(cancelText || submitText) && (
        <Form.Action>
          {cancelText && (
            <Button style={{ height: 40, width: 120, fontSize: 16, marginRight: 80 }} type="weak" onClick={onCancel}>
              {cancelText}
            </Button>
          )}
          {submitText && (
            <Button
              style={{ height: 40, width: 120, fontSize: 16 }}
              type="primary"
              htmlType="submit"
              loading={submitting}
              disabled={validating}
            >
              {submitText}
            </Button>
          )}
        </Form.Action>
      )}
    </form>
  );
}
